import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import { Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import SiteMeta from '../components/meta-data'
import Footer from '../components/footer'



class AcademyPage extends React.Component {
    
    render() {
        const metaData = get(this.props, 'data.site.siteMetadata.academyPage')
        const allModule = get(this.props, 'data.allContentfulModule.edges')
        const allAudience = get(this.props, 'data.allContentfulAcademyAudience.edges')
        return(
            <div>
                <SiteMeta metaData={metaData}/>
                <HeroSection/>            
                <AudienceSection data={allAudience}/>
                <ModuleSection data={allModule}/>
                <FormSection/>
                <Footer/>
            </div>    
        )
    }

}


class HeroSection extends React.Component {

    render() {
        const title = "Data driven digital marketing academy for Chinese market"
        return(
            <section className="hero hero-academy is-fullheight is-bold">
                <div className="hero-head">
                    <nav className="navbar is-transparent">
                        <div className="container">
                            <div className="navbar-brand">
                                <Link to = "/academy">
                                    <img src="/img/academy-logo.jpg" alt="academy logo" width="85" height="85"/>
                                </Link>
                            </div>
                        </div>
                    </nav>
                </div>    
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-vcentered">
                            <div className="column is-half">
                                <h1 className="title has-text-white has-text-left-desktop is-capitalized 
                                has-text-centered is-size-1 is-size-3-mobile">
                                    {title}
                                </h1>
                                <div className="has-text-left-desktop has-text-centered">
                                    <Link to = "/academy#form" className="button is-danger is-medium is-capitalized">
                                        Request for interest    
                                    </Link> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

class AudienceSection extends React.Component {
    
    render() {
        
        const title = "who is this academy for?"
        const {data} = this.props
        return(
            <section className="section audience">
                <div className="container">
                    <h1 className="title is-capitalized has-text-centered is-size-1 
                    is-size-3-mobile has-text-weight-bold">
                        {title}
                    </h1>    
                    <hr className="has-background-danger divider"/>
                    <div className="columns">
                        {
                            data.map(({node}, index) => {
                                return(
                                    <div className="column" key={index}>
                                        <div className="box has-text-centered has-background-light equal-height">
                                            <span className="fa-stack fa-4x has-text-danger">
                                                <i className="fas fa-circle fa-stack-2x"/>
                                                <i className={`fas ${node.fontAwesomeIcon} fa-stack-1x fa-inverse`}/>
                                            </span>
                                            <h2 className="has-text-black has-text-weight-medium is-size-3 is-capitalized">{node.title}</h2>
                                            <h3 className="is-size-4">{node.description.childMarkdownRemark.rawMarkdownBody}</h3>
                                        </div>
                                    </div>    
                                )
                            })
                        }

                    </div>
                </div>
            </section>
        )
    }
}

class ModuleSection extends React.Component {
    
    render(){

        const title = "What does the academy include?"
        const {data} = this.props
        return(
            <section className="section module">
                <div className="container">
                    <h1 className="title is-capitalized has-text-white has-text-centered is-size-1 
                    is-size-3-mobile has-text-weight-bold">
                        {title}
                    </h1>    
                    <hr className="has-background-danger divider"/>
                    <div className="columns is-multiline">
                        {
                            data.map(({node}, index)=>{
                                return(
                                    <div className="column is-one-third" key={index}>
                                        <div className="card equal-height">
                                            <div className="card-image">
                                                <figure className="image is-4by3">
                                                    <img src={`${node.icon.file.url}`} alt={`${node.title}`}/>
                                                </figure>
                                            </div>
                                            <div className="card-content">
                                                <div className="content">
                                                    <h2 className="has-text-black has-text-weight-medium is-size-3 is-capitalized">{node.title}</h2>
                                                    <p className="is-size-4">{node.description.childMarkdownRemark.rawMarkdownBody}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
        )
    }
}

class FormSection extends React.Component {

    constructor(props) {
		super(props)
		this.state = {
			messageSent: false
		}
		this.contactSchema = Yup.object().shape({
			name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required').matches(/[^0-9]/,'Numbers in the name?'),
			email: Yup.string().email('invalid email').required('Required!'),
			companyName: Yup.string().min(2, 'Too short!').required('Required!'),
			phoneNumber: Yup.number().required('Required').positive()
		})
	}

    render(){

        const title = "get your training brochure"
        return(
            <section id = "form" className="section request-form has-background-light">
                <div className="container">
                    <h1 className="title is-capitalized  has-text-centered is-size-1 
                    is-size-3-mobile has-text-weight-bold">
                        {title}
                    </h1>
                    <hr className="has-background-danger divider"/>
                    <div className="columns">
                        <div className="column is-6">
                            <figure className="image">
                                <img src="/img/academy-brochure.jpg" alt="red digial china academy brouchure"/>
                            </figure>
                        </div>
                        <div className="column is-6">
                            <Formik 
                                initialValues={{
                                    to:'sarahyam%40reddigitalchina.com',
                                    cc:'sunqqxp%40gmail.com',
                                    title:'Training Brouchure Request',
                                    name:'',
                                    email:'',
                                    companyName:'',
                                    phoneNumber:'',
                                }}
                                validationSchema={this.contactSchema}
                                onSubmit={(values, {resetForm}) => {
                                    // same shape as initial values
                                    axios({
                                        method: 'post',
                                        url:'https://8dbp32h7ge.execute-api.eu-west-1.amazonaws.com/dev/?format=json',
                                        data: `_to=${values["to"]}&_cc=${values["cc"]}&_replyTo=${values["email"]}&title=${values["title"]}&name=${values["name"]}&email=${values["email"]}&company=${values["companyName"]}&phone=${values["phoneNumber"]}`,
                                    }).then((response) => {
                                        if (response.data.statusCode==200) {
                                            this.setState(prevState => ({
                                                messageSent: !prevState.messageSent
                                            }))
                                        }
                                        resetForm({
                                            to:'sarahyam%40reddigitalchina.com',
                                            cc:'sunqqxp%40gmail.com',
                                            title:'Training Brouchure Request',
                                            name:'',
                                            email:'',
                                            companyName:'',
                                            phoneNumber:'',
                                        })
                                    }).catch((error) => {
                                        console.log(error)
                                    })
                                }}
                            >
                                {({errors, touched}) => (
                                    <div className="columns is-centered">
                                        <div className="column is-10">
                                            <Form id="Red Digital China Training brouchure Form">
                                                <div className="field">
                                                    <div className="field-body">
                                                        <div className="field">
                                                            <div className="control has-text-centered">
                                                                {this.state.messageSent ? (<p className="is-danger">Thank you for requesting training brouchure from Red Digtial Academy, our training advisor will contact you via email or phone within 1-2 days.</p>) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="field">
                                                    <label className="label is-medium">
                                                        Name*
                                                    </label>
                                                    <p className="control is-expanded has-icons-left">
                                                        <Field className="input is-medium" name="name" />
                                                        <span className="icon is-small is-left">
                                                            <i className="fas fa-user"></i>
                                                        </span>
                                                    </p>
                                                    {errors.name && touched.name ? (<p className="help is-danger">{errors.name}</p>) : null}    
                                                </div>
                                                <div className="field">
                                                    <label className="label is-medium">
                                                        Email*
                                                    </label>
                                                    <p className="control is-expanded has-icons-left">
                                                        <Field className="input is-medium" name="email" type="email"/>
                                                        <span className="icon is-small is-left">
                                                            <i className="fas fa-envelope"></i>
                                                        </span>
                                                    </p>
                                                    {errors.email && touched.email ? (<p className="help is-danger">{errors.email}</p>) : null}
                                                </div>
                                                <div className="field">
                                                    <label className="label is-medium">
                                                        Company*
                                                    </label>
                                                    <p className="control is-expanded has-icons-left">
                                                        <Field className="input is-medium" name="companyName"/>
                                                        <span className="icon is-small is-left">
                                                            <i className="fas fa-building"></i>
                                                        </span>
                                                    </p>
                                                    {errors.companyName && touched.companyName ? (<p className="help is-danger">{errors.companyName}</p>) : null}    
                                                </div>
                                                <div className="field">
                                                    <label className="label is-medium">
                                                        Phone*
                                                    </label>
                                                    <p className="control is-expanded has-icons-left">
                                                        <Field className="input is-medium" name="phoneNumber"/>
                                                        <span className="icon is-small is-left">
                                                            <i className="fas fa-phone-volume"></i>
                                                        </span>
                                                    </p>
                                                    {errors.phoneNumber && touched.phoneNumber ? (<p className="help is-danger">Wrong phone number format</p>) : null}
                                                </div>
                                                <div className="field">
                                                    <div className="control has-text-centered">
                                                        <button className="button is-danger is-medium" type="submit">Request For Interest</button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                )}	
                            </Formik>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}


export default AcademyPage

export const pageQuery = graphql`
    query AcademyPage {
        site {
            siteMetadata {
              academyPage {
                title
                description
                imageUrl
                siteUrl
              }
            }
          } 
        allContentfulAcademyAudience(filter: {node_locale: {eq: "en-GB"}}, sort: {fields: order}) {
            edges {
              node {
                order
                title
                fontAwesomeIcon
                description {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
            }
          }
          allContentfulModule(filter: {node_locale: {eq: "en-GB"}}, sort: {fields: order}) {
            edges {
              node {
                order
                title
                icon {
                  file {
                    url
                  }
                }
                description {
                  childMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
            }
          }        
    }
`